import CnpjUtils from "./cnpj-utils";
import RutUtils from "./rut-utils";
import CuitUtils from "./cuit-utils";

import NitUtils from "./nit-utils";
import NifUtils from "./nif-utils";
import SsnUtils from "./ssn-utils";
const codeCountry = [
{ id: 0,code:'CNPJ',description:'Cadastro de Pessoa Jurídica'},
{ id: 1,code:'CUIT',description:'Clave Única de Identificación Tributaria'},
{ id: 2,code:'NIT',description:'Número de Identificación Tributaria'},
{ id: 3,code:'RUT',description:'Registro Único Tributario'},
// { id: 4,code:'NIF',description:'Número de Identificación Fiscal'},
// { id: 5,code:'SSN',description:'Social Security Number'},
// { id: 6,code:'RNC',description:'Registro Nacional de Contribuyente'},
// { id: 7,code:'RUC',description:'Rol Único de Contribuyentes'},
// { id: 8,code:'NITE',description:'Número de Identificación Tributaria Especial'},
// { id: 9,code:'RTN',description:'Registro Tributario Nacional'},
// { id: 10,code:'TRN',description:'Taxpayer Registration Number'},
// { id: 11,code:'RFC',description:'Registro Federal de Contribuyentes'},


]
const DefaultUtils = {
     getCodeCountryOptions:() => {
        let options = []
    
        for (const [key, item] of Object.entries(codeCountry)) {
            options.push({ id:key, value: item.code, text: `${item.code} - ${item.description}` })
        }
    
        return options
    },
    ValidPattern:  (value,pattern) => {
        switch (pattern) {
          case "CNPJ":
            return CnpjUtils.cnpj(value);
          case "CUIT":
            return CuitUtils.cuit(value);
          case "RUT":
            return RutUtils.rut(value); 
          case "NIT":
            return NitUtils.nit(value);
          case "NIF":
            return NifUtils.nif(value);
          case "SSN":
            return SsnUtils.ssn(value);
          default:
            return CnpjUtils.cnpj(value);
        }
      },
    isValidValue:  ( code,pattern) => {
        switch (pattern) {
          case "CNPJ":
            return CnpjUtils.isValidValue(code);
          case "CUIT":
            return CuitUtils.isValidValue(code);
          case "RUT":
            return RutUtils.isValidValue(code); 
          case "NIT":
            return NitUtils.isValidValue(code);
          case "NIF":
            return NifUtils.isValidValue(code);
          case "SSN":
            return SsnUtils.isValidValue(code);
          default:
            return CnpjUtils.isValidValue(code);;
        }
      },

    applyMask: (value,pattern) => {

        switch (pattern) {
            case "CNPJ":
              return CnpjUtils.applyMask(value);
            case "CUIT":
              return CuitUtils.applyMask(value);
            case "RUT":
              return RutUtils.applyMask(value); 
            case "NIT":
              return NitUtils.applyMask(value);
              case "NIF":
              return NifUtils.applyMask(value);
              case "SSN":
              return SsnUtils.applyMask(value);
            default:
              return CnpjUtils.applyMask(value);;
          }

    },

    removeMask: (value,pattern) => {

        switch (pattern) {
            case "CNPJ":
              return CnpjUtils.removeMask(value);
            case "CUIT":
              return value;
            case "RUT":
              return value;
            case "NIT":
              return value;
            case "NIF":
              return value;
            case "SSN":
              return value;
            default:
              return CnpjUtils.removeMask(value);;
          }
    }

}

export default DefaultUtils