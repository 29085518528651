
const NifUtils = {

    isValidValue: (e) => {
        let value = e.target.value;

        // Check if the value contains only numbers and the last letter
        for (let i = 0; i < value.length; i++) {
            // Check if the first 8 characters are numbers
            if (i < 8 && isNaN(value[i])) {
                return false;
            }
            // Check if the last character is a letter
            if (i === 8 && !/[A-Za-z]/.test(value[i])) {
                return false;
            }
        }
    
        // Check if the value is the correct length for a nif
        if (value.length !== 9) {
            return false;
        }
    
        return true;
    },

    applyMask: (nif) => {
        // Remove non-numeric characters except letter
        nif = nif.replace(/[^0-9A-Za-z]/g, '').toUpperCase();
    
        let masked = '';
    
        // Iterate through characters and apply mask
        for (let i = 0; i < nif.length; i++) {
            masked += nif[i];
            // Add the hyphen before the final letter
            if (i === 7) {
                masked += '-';
            }
        }
    
        return masked;
    },

    removeMask: (nif) => {

        return nif.replace(/[^0-9A-Za-z]/g, '');
    },
    nif: (value) => {
        // Remove non-alphanumeric characters
        value = value.replace(/[^A-Za-z0-9]/g, '');

        // Check the general format of the NIF
        if (!/^[A-Z]?\d{7,8}[A-Z]$/.test(value)) {
            return 'O NIF deve ter o formato correto: 8 números seguidos de uma letra ou uma letra inicial, 7 números e uma letra final.';
        }

        // For NIFs of individuals: 8 numbers + 1 letter
        if (/^\d{8}[A-Z]$/.test(value)) {
            const numbers = value.slice(0, 8); // Get the 8 numbers
            const letter = value.slice(-1); // Take the last letter
            const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKE'; // Valid letters based on the remainder

            // Calculate the expected letter
            const expectedLetter = validLetters[numbers % 23];

            // Check if the letter is valid
            if (letter !== expectedLetter) {
                return 'NIF inválido para pessoas físicas.';
            }
        }

        // For NIFs of legal entities or foreigners: 1 initial letter + 7 numbers + 1 final letter
        if (/^[A-Z]\d{7}[A-Z]$/.test(value)) {
            const numbers = value.slice(1, 8); // Get the 7 numbers
            // Checking of initial and final letters may vary by type, not fully implemented here.
            // For simplicity, you can only validate the pattern.

            // If necessary, you can expand this part to validate specific letters.
        }


        return '';
    }

}

export default NifUtils