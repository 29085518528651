
const RutUtils = {

    isValidValue: (e) => {
        let value = e.target.value;
    
        if (/\s/.test(value)) {
            return false;
        }
    
        value = value ? value.replace(/\s+/g, '') : '';
    
        if (value.length > 12) {
            return false;
        }
    
        return true;
    },

    applyMask: (rut) => {
        // Remove any non-numeric characters
    rut = rut.replace(/\s+/g, '');

    // Check if the RUT has at least 2 characters (number + check digit)
    if (rut.length < 2) {
        return rut; // Returns as is, as masking is not possible
    }

    // Separate number and check digit
    const number = rut.slice(0, -1); // Numerical part
    const verifier = rut.slice(-1);  // Check digit

    // Combine number and check digit with hyphen
    return `${number}-${verifier}`;
    },

    removeMask: (rut) => {

        return rut ? rut.replace(/[^0-9]/g, '') : '';
    },
    rut: (value) => {
        value = value.replace(/\s+/g, '');

        if (value.length < 7 ) {
            return 'O RUT não pode ser  menos que 7 digitos';
        }
        if (value.length > 12) {
            return 'O RUT não pode ser  maior que 12 digitos';
        }
   

    return '';
    }

}

export default RutUtils