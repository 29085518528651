
const CuitUtils = {

    isValidValue: (e) => {
        let value = e.target.value;
    
        if (/\s/.test(value)) {
            return false;
        }
    
        value = value ? value.replace(/\s+/g, '') : '';
    
        if (value.length > 12) {
            return false;
        }
    
        return true;
    },

    applyMask: (cuit) => {
        // Remove non-numeric characters
        cuit = cuit.replace(/[^0-9]/gi, '');
    
        let masked = '';
    
        // Iterate through characters and apply mask
        for (let i = 0; i < cuit.length; i++) {
            masked += cuit[i];
            // Add the first hyphen after the first 2 digits
            if (i === 1) {
                masked += '-';
            }
            // Add the second hyphen after the first 10 digits
            if (i === 9) {
                masked += '-';
            }
        }
    
        return masked;
    },

    removeMask: (cuit) => {
        return cuit.replace(/[^0-9]/gi, '');
    },
    cuit: (value) => {
        // Remove non-numeric characters
        value = value.replace(/\.|\/|-/gi, '');

        // Convert string of digits to array of numbers
        let digits = value.split('').map(Number);

        // CUIT must contain exactly 11 digits
        if (digits.length !== 11)
            return 'O CUIT deve conter exatamente 11 números';

        // CUIT Standard Multipliers
        const multipliers = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

        // Extract the check digit (last digit)
        const verifier = digits.pop();

        // Perform the weighted sum calculation
        let sum = 0;
        for (let i = 0; i < digits.length; i++) {
            sum += digits[i] * multipliers[i];
        }

        // Calculate modulo 11
        const remainder = sum % 11;
        const calculatedVerifier = remainder === 0 ? 0 : 11 - remainder;

        // Check the check digit
        if (calculatedVerifier !== verifier)
            return 'CUIT inválido';

        return '';
    }

}

export default CuitUtils