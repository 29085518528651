
const CnpjUtils = {

    isValidValue: (e) => {

        let value = e.target.value

        for(let c of value){
            if(isNaN(c) && c !== '.' && c !== '/' && c !== '-')
                return false
        }

        return true
    },

    applyMask: (cnpj) => {

        cnpj = cnpj.replace(/\.|\/|-/gi, '')

        let masked = ''

        for(let i in cnpj){
            masked += cnpj[i]
            if(i === '1' || i === '4')
                masked += '.'
            if(i === '7')
                masked += '/'
            if(i === '11')
                masked += '-'
        }

        return masked
    },

    removeMask: (cnpj) => {

        return cnpj.replace(/\.|\/|-/gi, '')
    },
    cnpj: (value) => {
        value = value.replace(/\.|\/|-/gi, '')

        let digits = value.split('').map(Number)

        if(digits.length < 14)
            return 'O CNPJ deve conter 14 números'

        let multipliers = [5,4,3,2,9,8,7,6,5,4,3,2]

        let acc = []
        for(let i = 0; i < digits.length - 2; i++){
          acc[i] = digits[i] * multipliers[i]
        }

        if(acc.length === 0)
            return ''

        let sum = acc.reduce(function(a,b){ return a + b })
        let remainder = sum % 11
        let verification = remainder < 2 ? 0 : 11 - remainder

        if(verification !== digits[12])
            return 'CNPJ inválido'

        multipliers = [6,5,4,3,2,9,8,7,6,5,4,3,2]
        for(let i = 0; i < digits.length - 1; i++){
            acc[i] = digits[i] * multipliers[i]
        }

        sum = acc.reduce(function(a,b){ return a + b })
        remainder = sum % 11
        verification = remainder < 2 ? 0 : 11 - remainder

        if(verification !== digits[13])
            return 'CNPJ inválido'

        return ''
    },

}

export default CnpjUtils