
const SsnUtils = {

    isValidValue: (e) => {

        let value = e.target.value;

        // Check if value contains only numbers and hyphens
        for (let c of value) {
            if (isNaN(c) && c !== '-') {
                return false;
            }
        }
    
        // Verify that the value is the correct length for an SSN
        if (value.length !== 11) { // SSN has 9 numbers + 2 hyphens
            return false;
        }
    
        return true;
    },

    applyMask: (ssn) => {
        // Remove non-numeric characters
        ssn = ssn.replace(/[^0-9]/g, '');
    
        let masked = '';
    
        // Iterate through characters and apply mask
        for (let i = 0; i < ssn.length; i++) {
            masked += ssn[i];
            // Add the first hyphen after the first 3 digits
            if (i === 2) {
                masked += '-';
            }
            // Add the second hyphen after the first 5 digits
            if (i === 4) {
                masked += '-';
            }
        }
    
        return masked;
    },

    removeMask: (ssn) => {

        return ssn.replace(/-/g, '');
    },
    ssn: (value) => {
    // Remove non-numeric characters
    value = value.replace(/-/g, '');

    // SSN must be exactly 9 digits long
    if (value.length !== 9)
        return 'O SSN deve conter exatamente 9 números';

    // Check if all characters are numbers
    if (!/^\d{9}$/.test(value))
        return 'O SSN deve conter apenas números';

    // Extract parts of SSN
    const area = parseInt(value.substring(0, 3), 10);
    const group = parseInt(value.substring(3, 5), 10);
    const serial = parseInt(value.substring(5), 10);

    // Validate SSN rules
    if (area === 0 || area === 666 || area >= 900)
        return 'SSN inválido: área inválida';

    if (group === 0)
        return 'SSN inválido: grupo inválido';

    if (serial === 0)
        return 'SSN inválido: número de série inválido';

    return '';
    }

}

export default SsnUtils