import React, { useState, useEffect, useContext } from 'react'
import Checkbox from '../../../components/checkbox/checkbox'

import './update.css'
import Input from '../../../components/input/input';
import Form from '../../../components/form/form'
import UpdateTimePicker from './update-time-picker';
import { backendStatus, BackendContext } from '../../../backend/backend';
import { MainHeaderContext } from '../../../components/main-header/main-header-state';
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import UpdateVersions, { updateStatusEnum } from './update-versions';
import UpdateDeviceSelector from './update-device-selector';
import UpdateStats from './update-stats';
import { UserContext } from '../../user-context';
import { RemotizeType } from '../signup/signup';
import InfoPopup from '../../../components/info-popup/info-popup';
import UpdateValidating from './update-validating';
import UpdateTour from './update-tour';
import { UIEventsContext } from '../../uievents-context';
import Toggle from '../../../components/toggle/toggle';
//import CommonValidators from '../../../components/form/validators/common';

const updateMock = {
    "scope": "",
    "paused": false,
    "updateOnAssoc": true,
    "versions": [
        {
            "model": "W5-1200F",
            "version": "1.16.1",
            "status": 1,
            "start_time": Math.round((new Date()).getTime() / 1000) - 30000
        },
        {
            "model": "W5-1200G",
            "version": "1.16.3",
            "status": 3,
            "start_time": 1616176532
        },
        {
            "model": "W4-300F",
            "version": "1.17.5",
            "status": 0,
            "start_time": 1616176532
        }
    ],
    "auto": true,
    "days": 3,
    "windowStart": 0,
    "windowEnd": 0
}

const summerTimeDisabled = process.env.REACT_APP_DISABLE_SUMMER_TIME
const BRASILIA_OFFSET = 180

const localFromUTC = (minutes) => {

    let offset = summerTimeDisabled ? BRASILIA_OFFSET : Date().getTimezoneOffset()

    minutes = minutes - offset

    return minutes
}

const UTCFromLocal = (minutes) => {

    let offset = summerTimeDisabled ? BRASILIA_OFFSET : Date().getTimezoneOffset()

    minutes = minutes + offset

    return minutes
}

const fetchUpdate = async (setUpdate, setLoading, setTimeWindow, backend, tour) => {

    let result = await backend.retrieveFresh('updates')

    let update = result.content

    setTimeWindow({
        start: localFromUTC(update.windowStart),
        end: localFromUTC(update.windowEnd),
    })

    updateMock.scope = update.scope
    updateMock.windowStart = update.windowStart
    updateMock.windowEnd = update.windowEnd
    setUpdate(!tour ? update : updateMock)
}

const getFormatedTime = (minutes) => {
    return `${Math.floor(minutes / 60).pad()}:${(minutes % 60).pad()}`
}

const UpdatePage = () => {

    const [update, setUpdate] = useState(null)
    const [loading, setLoading] = useState(true)

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [tour, setTour] = useState(false)

    const [firmwares, setFirmwares] = useState([])

    const [timeWindow, setTimeWindow] = useState({
        start: 0,
        end: 0
    })

    const header = useContext(MainHeaderContext)
    const backend = useContext(BackendContext)
    const [user,] = useContext(UserContext)
    const uievents = useContext(UIEventsContext)

    useEffect(() => {

        header.title.set('Atualização')

        fetchFirmwares()
        fetchUpdate(setUpdate, setLoading, setTimeWindow, backend, tour)

        uievents.setHandler('updates', () => {
            fetchUpdate(setUpdate, setLoading, setTimeWindow, backend, tour)
        })

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        fetchFirmwares()
        fetchUpdate(setUpdate, setLoading, setTimeWindow, backend, tour)

        // eslint-disable-next-line
    }, [tour])

    useEffect(() => {

        if (update)
            setLoading(false)

    }, [update])

    const fetchFirmwares = async () => {

        let { status, content } = await backend.retrieve('firmwares')
        if (status !== backendStatus.SUCCESS) {
            console.error('Error:', content)
            return
        }

        setFirmwares(!tour ? content : require('./firmwares_mock.json'))
    }

    const sendUpdate = async (updateCopy) => {

        setSaving(true)

        delete updateCopy.versions
        updateCopy["user"] = user.email

        updateCopy.windowStart = UTCFromLocal(timeWindow.start)
        updateCopy.windowEnd = UTCFromLocal(timeWindow.end)

        let result = await backend.update('updates', updateCopy)

        if (result.status === backendStatus.ERROR) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

        fetchUpdate(setUpdate, setLoading, setTimeWindow, backend, tour)
    }


    const continueFn = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const onUpdateListAction = () => {
        fetchUpdate(setUpdate, setLoading, setTimeWindow, backend, tour)
    }

    const thereIsValidationsRunning = () => {
        for (let version of update.versions)
            if (version.status === updateStatusEnum.VALIDATING || version.status === updateStatusEnum.STOPPED)
                return true
        return false
    }


    return loading ? <div className='loading'></div> : <div id='update-page' className='page container'>

        <DefaultStatusModals
            saving={saving}
            success={success}
            error={error}
            continueFn={continueFn}
        ></DefaultStatusModals>


        <div className='update-page-container'>

            <div className='update-page-section update-card' id='update-configs'>

                <div className='subtitle'>Configurações da atualização em massa</div>


                <div className='update-enable-field'>
                    <Toggle id='update-enable'
                        label='Habilitar atualização'
                        value={!update.paused}
                        toggleFn={() => {
                            let newUpdate = ({ ...update, paused: !update.paused })
                            setUpdate({ ...newUpdate })
                            sendUpdate(newUpdate)
                        }}
                        tooltip='Habilita/desabilita todas as funções de atualização'
                    >
                    </Toggle>
                </div>

                {update.paused && (
                    <div className='update-disabled-info'>
                    As funções de atualização estão desabilitadas. Para atualizar seus roteadores, habilite a função.
                </div>)}
                    
                {    <Form id='update-form'
                        onSubmit={() => {
                            sendUpdate({ ...update })
                        }}
                        hideButton={user.type !== RemotizeType.ADMIN}
                    >

                        <UpdateTimePicker
                            window={timeWindow}
                            setWindow={setTimeWindow}
                            user={user}
                            update={update}
                            label={`A atualização em massa de dispositivos feita pela
                                ativação de versão e pela atualização automática controlada
                                será realizada dentro desta janela de tempo.`}
                        ></UpdateTimePicker>

                        <UpdateVersions
                            update={update}
                            startTime={getFormatedTime(timeWindow.start)}
                            endTime={getFormatedTime(timeWindow.end)}
                            onAction={onUpdateListAction}
                            firmwares={firmwares}
                        ></UpdateVersions>


                        {user.type === RemotizeType.ADMIN ? [

                            <div className='with-info-group' key='update-2' id='update-2'>
                                <Checkbox
                                    id='assoc-update-toggle'
                                    label='Atualizar imediatamente novos dispositivos associados'
                                    disabled={update.paused}
                                    toggleFn={() => {
                                        update.updateOnAssoc = !update.updateOnAssoc
                                        setUpdate({ ...update })
                                    }}
                                    value={update.updateOnAssoc}
                                ></Checkbox>
                                <InfoPopup
                                    message='Todos os novos dispositivos associados à sua conta
                                serão imediatamente atualizados com a versão ativa,
                                independente da janela de tempo configurada.'
                                ></InfoPopup>
                            </div>,

                            /*<div className='with-info-group' key='update-3' id='update-3'>
                                <Checkbox
                                    id='limit-update-toggle'
                                    label='Habilitar o limite de dispositivos atualizados por janela'
                                    toggleFn={() => {
                                        update.limited = !update.limited
                                        setUpdate({ ...update })
                                    }}
                                    value={update.limited}
                                ></Checkbox>
                                <InfoPopup
                                    message='A cada janela de atualização, o Remotize enviará pedidos de atualização
                                    para, no máximo, o número de dispositivos definido no campo
                                    "Limite máximo de atualizações por janela" abaixo.'
                                ></InfoPopup>
                            </div>,

                            update.limited && <div key='update-limited'>
                                <Input
                                    id='update-limit-input'
                                    label="Limite máximo de atualizações por janela"
                                    value={update.limitValue}
                                    onChange={e => {
                                        if(isNaN(e.target.value)) return
                                        update.limitValue = Number(e.target.value)
                                        setUpdate({...update})
                                    }}
                                    validators={[{fn:CommonValidators.value, params:{min: 1}}]}
                                ></Input>
                            </div>,*/
                            <div className='with-info-group' key='update-4' id='update-4'>
                                <Checkbox
                                    id='auto-update-toggle'
                                    label='Ativar atualização automática controlada'
                                    disabled={update.paused}
                                    toggleFn={() => {
                                        update.auto = !update.auto
                                        setUpdate({ ...update })
                                    }}
                                    value={update.auto}
                                ></Checkbox>
                                <InfoPopup
                                    message='Avalie a versão disponível em dispositivos
                                de teste durante determinado número de dias antes de
                                ativar automaticamente a nova versão para toda a sua rede.'
                                ></InfoPopup>
                            </div>,

                            update.auto && <div style={{ display: 'block' }} key='update-5'>
                                <div id='auto-update-days-input'>
                                    <Input
                                        id='auto-update-days'
                                        label='Tempo de teste (dias)'
                                        collapse={!update.auto}
                                        value={update.days}
                                        disabled={update.paused}
                                        onChange={(e) => {

                                            if (isNaN(e.target.value))
                                                return

                                            let value = Number(e.target.value)

                                            if (value > 365) return

                                            update.days = value
                                            setUpdate({ ...update })
                                        }}
                                    ></Input>
                                </div>

                                <div className='section-divider' key='update-6'></div>

                                <UpdateDeviceSelector key='update-7' id='update-7' tour={tour} update={update}></UpdateDeviceSelector>

                            </div>

                        ] : null}

                    </Form>
                }

            </div>

            {

                <div className='update-page-section update-card'>

                    <div id='update-overview'>
                        <div className='subtitle'>Visão geral</div>

                        <UpdateStats update={update} firmwares={firmwares} tour={tour}></UpdateStats>
                    </div>

                    {thereIsValidationsRunning() ?
                        <UpdateValidating
                            update={update}
                            firmwares={firmwares}
                        ></UpdateValidating>
                        : null}

                </div>
            }
        </div>
        <UpdateTour setTour={setTour} />
    </div>
}

export default UpdatePage
