import React, {useState, useEffect, useContext} from 'react'
import List from '../../../components/list/list'
import Modal, {ModalTypes} from '../../../components/modal/modal'
import { BackendContext, backendStatus } from '../../../backend/backend'
import { useToasts } from 'react-toast-notifications'
import ThumbIcon from '../../../components/icons/thumb-icon'
import { UserContext } from '../../user-context'
import { RemotizeType } from '../signup/signup'
import StopIcon from '../../../images/stop.svg'
import PlayIcon from '../../../images/play.svg'

export const updateStatusEnum = {ACTIVE: 0, VALIDATING: 1, PENDING: 2, STOPPED: 3}

let confirmFn = null

export default function UpdateVersions({update, startTime, endTime, onAction, firmwares}){

    const [versions, setVersions] = useState([...update.versions])
    const [confirmActivation, setConfirmActivation] = useState({show: false, model: ''})

    const backend = useContext(BackendContext)
    const [user,] = useContext(UserContext)
    const { addToast } = useToasts()

    useEffect(() => {

        setVersions([...update.versions])

        // eslint-disable-next-line 
    }, [update])

    const updateVersionStatus = async(model, status) => {

        let updateVersion = getVersion(model)

        if(status === updateStatusEnum.ACTIVE)
            updateVersion.version = getActiveFirmwareVersion(model)

        updateVersion.status = status

        let result = await backend.update('updates/versions', updateVersion)

        if(result.status === backendStatus.ERROR){
            addToast('Erro ao salvar informações de atualização.', {
                appearance: 'error',
                autoDismiss: true,
            })
            return
        }

        addToast('Informações de atualização salvas com sucesso.', {
            appearance: 'success',
            autoDismiss: true,
        })
    }

    const getVersion = (model) => {
        for(let updateVersion of versions){
            if(updateVersion.model === model)
                return updateVersion
        }

        return {}
    }

    const calculateDate = (startTime, daysToAdd,widhDays) =>{
        // Convert timestamp to milliseconds and create new date
        const date = new Date(startTime * 1000);
    
        // Add the days
        if (widhDays) {
            date.setDate(date.getDate() + daysToAdd);
        }
        
    
        //  to dd/mm/yyyy format
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if necessary
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months start at 0
        const year = String(date.getFullYear()).slice(-2);
    
        return `${day}/${month}/${year}`;
    }

    const getActiveFirmwareVersion = (model) => {
        for(let firmware of firmwares) {
            if(firmware.model === model)
                return firmware.version
        }

        return ''
    }

    const getLines = () => {
        let lines = []
        for(let {model,status,version,start_time} of versions){
            let officialVersion = getActiveFirmwareVersion(model)
            let line = [model, version, officialVersion]

            if(user.type === RemotizeType.ADMIN && !update.paused){
                line.push(getUpdateActions(model, status,version,officialVersion))
                if(status === updateStatusEnum.VALIDATING ||  status === updateStatusEnum.STOPPED ){
                    line.push(getTrialPeriod(start_time))
                }else{
                    line.push('')
                }
            }
               
            lines.push(line)
        }

        return lines
    }

    const getActivateAction = (model) => {

        if(user.type !== RemotizeType.ADMIN) return null

        return (
            <button className='update-action update-activate' id='update-action-active' onClick={async(e) => {

                e.preventDefault()

                confirmFn = async(confirmed) => {
                    if(confirmed){
                        await updateVersionStatus(model, updateStatusEnum.ACTIVE)
                    }
                    setConfirmActivation({show:false, model: ''})
                    onAction()
                }

                setConfirmActivation({show: true, model: model})
            }}


            >
                <ThumbIcon size={20}></ThumbIcon>
                <span>ativar</span>
            </button>
        )
    }

    const getStopAction = (model) => {

        if(user.type !== RemotizeType.ADMIN) return null

        return (
            <button className='update-action update-deactivate' id='update-action-stop' onClick={async(e) => {

                    e.preventDefault()

                    await updateVersionStatus(model, updateStatusEnum.STOPPED)
                    onAction()
                }}
            >
                <img alt='' src={StopIcon} width='18px'></img>
                <span>parar</span>
            </button>
        )
    }

    const getRestartAction = (model) => {

        if(user.type !== RemotizeType.ADMIN) return null

        return (
            <button className='update-action update-deactivate' id='update-action-restart' onClick={async(e) => {

                    e.preventDefault()

                    await updateVersionStatus(model, updateStatusEnum.VALIDATING)
                    onAction()
                }}
            >
                <img alt='' src={PlayIcon} width='18px'></img>
                <span>reiniciar</span>
            </button>
        )
    }


    const getUpdateActions = (model, status,version, officialVersion) => {
        return <div className='update-actions'>
                { status === updateStatusEnum.ACTIVE  && version !== officialVersion ? getActivateAction(model)  :null}
                { status >= updateStatusEnum.VALIDATING ? getActivateAction(model) : null }

                { status === updateStatusEnum.VALIDATING ? getStopAction(model) : null }

                { status === updateStatusEnum.STOPPED ? getRestartAction(model) : null }

            </div>
    }
    
    const getTrialPeriod = (start_time) => {
        return (<div className='trial-period'>
                    <span>{`${calculateDate(start_time,0,false)}-${calculateDate(start_time,update.days,true)}`}</span>
                </div>)
    }
    const getActivateConfirmationModal = () => {

        let version = getActiveFirmwareVersion(confirmActivation.model)

        return (
            <Modal
                title='Atualização de dispositivos'
                show={confirmActivation.show}
                type={ModalTypes.CONFIRM}
                onDismissClick={confirmFn}
                content={<div style={{width:'400px'}}>
                    <br></br>
                    Ao ativar, todos os dispositivos {confirmActivation.model} serão atualizados
                    para a versão {version} entre {startTime} e  {endTime} horas.
                    Recomendamos que sempre mantenha seu parque atualizado.
                    <br></br>
                    <br></br>
                    <b>Deseja ativar a versão {version}?</b>
                </div>}
                dismissText='Não, obrigado'
                confirmText='Sim, quero ativar'
            ></Modal>
        )

    }

    const getColumns = () => {

        let columns = [
            {header: 'Modelo'},
            {header: 'Versão ativa'},
            {header: 'Última versão disponível', align: 'center'},
        ]
        
        if(user.type === RemotizeType.ADMIN ){
            if(!update.paused){
                columns.push({header:'Ações sobre versão disponível', align: 'center'})
            }
             columns.push({header:'Período de Testes', align: 'center'})
        }
          
    
        return columns
    }

    return (
        versions.length === 0 ? <div className='update-no-devices'>
            Você ainda não possui dispositivos para atualizar
        </div> : <React.Fragment>

                {getActivateConfirmationModal()}

                <List
                columns={getColumns()}
                lines={getLines()}
            >
            </List>


        </React.Fragment>
    )

}